module.exports = [{
      plugin: require('/home/travis/build/Ricool06/ricool.uk/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ricool's blog","short_name":"Ricool","start_url":"/","background_color":"#339988","theme_color":"#339988","display":"minimal-ui","icon":"src/images/liontraceroar.png"},
    },{
      plugin: require('/home/travis/build/Ricool06/ricool.uk/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200},
    },{
      plugin: require('/home/travis/build/Ricool06/ricool.uk/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
